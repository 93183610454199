
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { ElementDetail, StandardDetail } from '@/types/patrol'

@Component({
  name: 'ElementAddPage'
})
export default class extends Vue {
  private cycleList = ['日', '周', '月']
  private riskLevelList = []
  private disabled = false

  private formData: ElementDetail = {
    riskLevel: '',
    standardList: [],
    corpFrequency: 1,
    corpFrequencyType: 1,
    cooperateFrequency: 1,
    cooperateFrequencyType: 1,
    controlMeasures: '',
    emergencyDisposal: '',
    elementName: ''
  }

  private rules = {
    standardList: [
      { required: true, message: '请添加检查标准', trigger: ['change'] }
    ]
  }

  private standardForm = {
    standardContent: ''
  }

  private standardRules = {
    standardContent: [
      { required: true, message: '请输入检查标准', trigger: ['blur', 'change'] }
    ]
  }

  get elementId () {
    return this.$route.params.elementId
  }

  created () {
    this.elementId && this.loadData()
    this.loadRiskLevelList()
  }

  loadRiskLevelList () {
    this.$axios.get(this.$apis.common.selectDicByList, {
      dicType: 'riskLevel'
    }).then(res => {
      this.riskLevelList = res.riskLevel || []
    })
  }

  loadData () {
    this.$axios.get(this.$apis.patrol.selectElementByElementId, {
      elementId: this.elementId
    }).then(res => {
      res.standardList.map((item: StandardDetail) => {
        item.isEdit = false
        return item
      })
      this.formData = res || {}
    })
  }

  onAdd () {
    if (this.formData.standardList.some(item => item.isEdit)) {
      this.$message.warning('请先保存正在编辑的标准')
    } else if (this.formData.standardList.length < 50) {
      this.formData.standardList.push({
        standardContent: '',
        isEdit: true
      })
    } else {
      this.$message.warning('检查标准最多只能添加50条')
    }
  }

  onEdit (row: StandardDetail) {
    const editIndex = this.formData.standardList.findIndex(item => item.isEdit)
    if (editIndex !== -1) {
      this.onCancel(this.formData.standardList[editIndex], editIndex)
    }
    this.standardForm = JSON.parse(JSON.stringify(row))
    row.isEdit = true
  }

  onCancel (row: StandardDetail, index: number) {
    if (row.standardId) {
      row.isEdit = false
    } else {
      this.formData.standardList.splice(index, 1)
    }
    this.standardForm = {
      standardContent: ''
    }
  }

  onSave (row: StandardDetail, index: number) {
    (this.$refs.standardForm as ElForm).validate().then(() => {
      const index2 = this.formData.standardList.findIndex(item => item.standardContent === this.standardForm.standardContent)
      if (index2 !== -1 && index2 !== index) {
        this.$message.warning('该标准已经添加，请不要重复添加')
      } else {
        row.isEdit = false
        this.formData.standardList[index].standardContent = this.standardForm.standardContent
        this.standardForm.standardContent = ''
      }
    })
  }

  onDelete (index: number) {
    this.formData.standardList.splice(index, 1)
  }

  submit () {
    (this.$refs.form as ElForm).validate().then(() => {
      if (this.formData.standardList.some(item => item.isEdit)) {
        this.$message.warning('请先保存正在编辑的标准')
      } else {
        this.disabled = true
        const formData = JSON.parse(JSON.stringify(this.formData))
        formData.standardList.forEach((item: StandardDetail) => {
          delete item.isEdit
        })
        console.log('formData', formData)
        const url = this.elementId ? this.$apis.patrol.updateElement : this.$apis.patrol.insertElement
        this.$axios.post(url, {
          ...formData
        }).then(() => {
          this.$message.success(this.elementId ? '编辑成功' : '新增成功')
          this.$router.push({ name: 'patrolElementList', params: { type: this.$route.params.type } })
        }).finally(() => {
          this.disabled = false
        })
      }
    })
  }
}
